/**
 * This module re-exports all pages of the app as lazy loaded components
 * @module asyncPages
 * @summary File containing all pages as lazy loaded components
 */

import React from 'react'
import Loadable from 'react-loadable'
import Loader from '@zeliot/common/ui/Loader'

/**
 * @summary Map dashboard page with Google maps
 */
export const AsyncGoogleMapsDashboard = Loadable({
  loader: () => import('@zeliot/core/base/pages/MapDashboard'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Map dashboard page with Openstreet Maps
 */
export const AsyncOSMapsDashboard = Loadable({
  loader: () => import('@zeliot/core/base/pages/OSMap'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Analytics dashboard page
 */
export const AsyncAnalyticsDashboard = Loadable({
  loader: () => import('@zeliot/core/base/pages/AnalyticsDashboard'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary OBD page
 */
export const AsyncOBD = Loadable({
  loader: () => import('@zeliot/core/base/pages/OBD'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Report page
 */
export const AsyncReport = Loadable({
  loader: () => import('@zeliot/core/base/pages/Report'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary HeatMap page
 */
export const AsyncHeatMap = Loadable({
  loader: () => import('@zeliot/core/base/pages/HeatMap'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Immobilization page
 */
export const AsyncImmobilize = Loadable({
  loader: () => import('@zeliot/core/base/pages/Immobilize'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Alerts dashboard page
 */
export const AsyncAlertsDashboard = Loadable({
  loader: () => import('@zeliot/core/base/pages/AlertsDashboard'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Routes page
 */
export const AsyncRoutes = Loadable({
  loader: () => import('@zeliot/core/base/pages/Routes'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary AOI page
 */
export const AsyncAOI = Loadable({
  loader: () => import('@zeliot/core/base/pages/Aoi'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary School AOI page
 */
export const AsyncSchoolAOI = Loadable({
  loader: () => import('@zeliot/school/base/pages/SchoolAoi'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Trips page
 */
export const AsyncTrips = Loadable({
  loader: () => import('@zeliot/core/base/pages/Trips'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Activity page
 */
export const AsyncActivity = Loadable({
  loader: () => import('@zeliot/core/base/pages/Activity'),
  loading: () => <Loader fullscreen={true} />
})

export const BctManagement = Loadable({
  loader: () => import('packages/core/base/pages/BCTManagement'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Vehicle Management And Configuration page
 */
export const AsyncVehiclesManagementConfig = Loadable({
  loader: () => import('@zeliot/core/base/pages/VehiclesManagementConfig'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary DTC Configuration page
 */
export const AsyncDTCConfiguration = Loadable({
  loader: () => import('@zeliot/core/base/pages/DTCConfiguration'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Users page
 */
export const AsyncUsers = Loadable({
  loader: () => import('@zeliot/core/base/pages/Users'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Vehicles page
 */
export const AsyncVehicles = Loadable({
  loader: () => import('@zeliot/core/base/pages/Vehicles'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Drivers page
 */
export const AsyncDrivers = Loadable({
  loader: () => import('@zeliot/core/base/pages/Drivers'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Account page
 */
export const AsyncAccount = Loadable({
  loader: () => import('@zeliot/core/base/pages/Account'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Fuel dashboard page
 */
export const AsyncFuelDashboard = Loadable({
  loader: () => import('@zeliot/core/base/pages/FuelDashboard'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Vehicle score page
 */
export const AsyncVehicleScore = Loadable({
  loader: () => import('@zeliot/core/base/pages/VehicleScore'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary School dashboard page
 */
export const AsyncSchoolDashboard = Loadable({
  loader: () => import('@zeliot/school/base/pages/SchoolDashboard'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary School Routes page
 */
export const AsyncSchoolRoutes = Loadable({
  loader: () => import('@zeliot/school/base/pages/SchoolRoute'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary School Trips page
 */
export const AsyncSchoolTrip = Loadable({
  loader: () => import('@zeliot/school/base/pages/SchoolTrip'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Schools management page
 */
export const AsyncSchool = Loadable({
  loader: () => import('@zeliot/school/base/pages/School'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Students management page
 */
export const AsyncSchoolStudents = Loadable({
  loader: () => import('@zeliot/school/base/pages/Students'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Electric vehicles summary page
 */
export const AsyncElectricSummary = Loadable({
  loader: () => import('@zeliot/core/base/pages/Summary'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Video stream page
 */
export const AsyncVideoStream = Loadable({
  loader: () => import('@zeliot/core/base/pages/VideoStream'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary REIL video demo page
 */
export const AsyncReilVideo = Loadable({
  loader: () => import('@zeliot/core/base/pages/ReilVideo'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Continental TPM integration page
 */
export const AsyncContinental = Loadable({
  loader: () => import('@zeliot/core/base/pages/Continental'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Raw data files explorer page
 */
export const AsyncRawDataFiles = Loadable({
  loader: () => import('@zeliot/core/base/pages/RawDataFilesExplorer'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Provisioning page
 */
export const Provisioning = Loadable({
  loader: () => import('@zeliot/core/base/pages/Provisioning'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary EOL Dashboard page
 */
export const EOLDashboard = Loadable({
  loader: () => import('@zeliot/core/base/pages/EOLDashboard'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary EOL Report page
 */
export const EOLReport = Loadable({
  loader: () => import('@zeliot/core/base/pages/EOLReport'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Fota management page
 */
export const FotaManagement = Loadable({
  loader: () => import('@zeliot/core/base/pages/FotaManagement'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Sim management page
 */
export const InventorySim = Loadable({
  loader: () => import('@zeliot/core/base/pages/InventorySims'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Device management page
 */
export const Devices = Loadable({
  loader: () => import('@zeliot/core/base/pages/InventoryDevices'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Insights page
 */
export const Insights = Loadable({
  loader: () => import('@zeliot/school/base/pages/Insights'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary ReVehicles management page
 */
export const ReVehicles = Loadable({
  loader: () => import('@zeliot/core/base/pages/ReVehicles'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Manage Mechanic page
 */
export const ManageMechanic = Loadable({
  loader: () => import('@zeliot/core/base/pages/ManageMechanic'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Tcu Device page
 */
export const TcuDevice = Loadable({
  loader: () => import('@zeliot/core/base/pages/InventoryDevices/TcuDevice'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Dongal page
 */
export const DongalDevice = Loadable({
  loader: () => import('@zeliot/core/base/pages/InventoryDevices/DongalDevice'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Dongal page
 */
export const InventoryManagement = Loadable({
  loader: () =>
    import('@zeliot/core/base/pages/InventoryDevices/InventoryManagement'),
  loading: () => <Loader fullscreen={true} />
})
/**
 * @summary Live Data page
 */
export const LiveData = Loadable({
  loader: () => import('@zeliot/core/base/pages/LiveData'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Data sync report page
 */
export const SyncData = Loadable({
  loader: () => import('@zeliot/core/base/pages/SyncData'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Content management page
 */
export const ContentManagement = Loadable({
  loader: () => import('@zeliot/core/base/pages/ContentManagement'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Vehicle Health Report page
 */
export const VehicleHealthReport = Loadable({
  loader: () => import('@zeliot/core/base/pages/VehicleHealthReport'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Logs From Elastic Search
 */
export const AsyncLogsFromES = Loadable({
  loader: () => import('@zeliot/core/base/pages/LogsFromES'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Mechanic Provision Reports page
 */
export const MechanicProvisionReports = Loadable({
  loader: () => import('@zeliot/core/base/pages/MechanicProvisionReports'),
  loading: () => <Loader fullscreen={true} />
})

/**
 * @summary Mechanic Notifications page
 */
export const MechanicNotifications = Loadable({
  loader: () => import('@zeliot/core/base/pages/MechanicNotifications'),
  loading: () => <Loader fullscreen={true} />
})
